import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function CustomPagination({
  count,
  color = "primary",
  disabled = false,
  variant = "text",
  size = "large",
  onChange,
  siblingCount = 1,
  boundaryCount = 1,
}) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  let responsiveCount = count;

  if (isXs) {
    responsiveCount = Math.min(count, 3); // Show only 3 pages on extra-small screens
  } else if (isSm) {
    responsiveCount = Math.min(count, 4); // Show only 5 pages on small screens
  } else if (isMd) {
    responsiveCount = Math.min(count, 7); // Show only 7 pages on medium screens
  }

  return (
    <Stack spacing={2}>
      <Pagination
        count={responsiveCount}
        color={color}
        disabled={disabled}
        variant={variant}
        size={size}
        onChange={(ev, pageNumber) => {
          window.scrollTo(0, 0);
          onChange(ev, pageNumber);
        }}
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        sx={{
          "& .MuiPaginationItem-icon": {
            borderRadius: "50%",
            border: "1px solid #e9e8e8",
            fontSize: 20,
            padding: 1,
          },
          "& .Mui-selected": {
            backgroundColor: "#0B3013!important", // Change the background color of the active page
            color: "#FFF", // Change the text color of the active page
            border: "1px solid #e9e8e8", // Maintain border color for the selected page
            "&:hover": {
              backgroundColor: "#0B3013", // Change the background color on hover
            },
          },
          "& .MuiPaginationItem-page:hover": {
            backgroundColor: "transparent", // Remove default hover background color
          },
        }}
      />
    </Stack>
  );
}
