import React from "react";
import { Container, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="mainSection">
      <Container maxWidth="xl" sx={{ px: { md: 8, xl: 4, xxl: 4 }, py: 3 }}>
        <Box sx={{ py: 1.5 }}>
          <Box className="terms-main" sx={{ py: 1.5 }}>
            <h4 className="terms-heading">{t("privacyPolicy")}</h4>
          </Box>
        </Box>
        {i18n.language == "en" ? (
          <Box
            className="terms-content"
            style={{
              backgroundColor: "#fff",
              borderRadius: "20px",
            }}
          >
            <p>
              This Privacy Policy outlines our practices regarding the
              collection, use, and disclosure of your information when you use
              our service (the "Service"). By accessing or using the Service,
              you agree to the collection and use of your information in
              accordance with this policy.
            </p>
            <h3>Entity Reference</h3>

            <p>
              The entity responsible for your Personal Data is WorkWaveHub,
              referred to as "we," "us," or "our."
            </p>
            <h3>Policy Labeling</h3>

            <p>
              This document is labeled as the "Privacy Policy" and outlines our
              practices regarding the handling of personal data.
            </p>
            <h3>Contact Information</h3>

            <p>
              For any questions about this Privacy Policy or if you would like
              to exercise your rights regarding your Personal Data, you can
              reach us at:
            </p>
            <ul>
              <li>
                <strong>Email</strong>&nbsp;:&nbsp;
                <a href="mailto:contact@workwavehub.com">
                  contact@workwavehub.com
                </a>
              </li>
              <li>
                <strong>Website</strong>&nbsp;:&nbsp;
                <a href="https://workwavehub.com/contact">
                  http://workwavehub.com/contact
                </a>
              </li>
            </ul>
            <h3>Accessibility</h3>

            <p>
              The Privacy Policy is accessible in a standard web browser and
              does not require plugins or additional software. It is hosted on
              an active, public, non-geofenced URL and is non-editable by the
              public.
            </p>
            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>

            <p>
              Words with initial capitalization have specific meanings defined
              here. These meanings apply whether used in singular or plural
              forms.
            </p>
            <h3>Definitions</h3>

            <p>For this Privacy Policy:</p>
            <ul>
              <li>
                <strong>Account</strong>&nbsp;: A unique account created for you
                to access our Service.
              </li>
              <li>
                <strong>Affiliate</strong>: An entity that controls, is
                controlled by, or is under common control with a party.
              </li>
              <li>
                <strong>Application</strong>: Refers to WorkWaveHub, the
                software provided by us.
              </li>
              <li>
                <strong>Company</strong>: WorkWaveHub, referred to as "we,"
                "us," or "our."
              </li>
              <li>
                <strong>Cookies</strong>: Small files placed on your device that
                contain details of your browsing history.
              </li>
              <li>
                <strong>Country</strong>: Refers to Iran.
              </li>
              <li>
                <strong>Device</strong>: Any device capable of accessing the
                Service, such as a computer or mobile device.
              </li>
              <li>
                <strong>Personal Data</strong>: Information that relates to an
                identified or identifiable individual.
              </li>
              <li>
                <strong>Service</strong>: The Application or the Website or
                both.
              </li>
              <li>
                <strong>Service Provider</strong>&nbsp;: Any entity that
                processes data on our behalf.
              </li>
              <li>
                <strong>Third-party Social Media Service</strong>: Websites or
                social networks allowing you to log in or create an account for
                the Service.
              </li>
              <li>
                <strong>Usage Data</strong>: Data collected automatically,
                generated by the Service or its infrastructure.
              </li>
              <li>
                <strong>Website</strong>&nbsp;: WorkWaveHub, accessible at&nbsp;
                <a href="https://workwavehub.com/">
                  https://www.workwavehub.com/
                </a>
                .
              </li>
              <li>
                <strong>You</strong>&nbsp;: The individual or legal entity
                accessing or using the Service.
              </li>
            </ul>
            <h2>Collecting and Using Your Personal Data</h2>
            <h3>Types of Data Collected</h3>

            <p>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </p>
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
            </ul>
            <h3>Use of Your Personal Data</h3>

            <p>
              We may use your Personal Data for various purposes, including:
            </p>
            <ul>
              <li>Providing and maintaining our Service.</li>
              <li>Managing your Account.</li>
              <li>Contacting you with updates and promotional information.</li>
              <li>Analyzing data to improve our Service.</li>
            </ul>
            <h3>Sharing Your Personal Data</h3>

            <p>
              We may share your personal information in the following
              situations:
            </p>
            <ul>
              <li>
                <strong>With Service Providers</strong>: To monitor and analyze
                usage.
              </li>
              <li>
                <strong>For Business Transfers</strong>: During negotiations of
                mergers or acquisitions.
              </li>
              <li>
                <strong>With Affiliates</strong>: With our parent company or
                subsidiaries.
              </li>
              <li>
                <strong>With Business Partners</strong>: To offer specific
                products or services.
              </li>
              <li>
                <strong>With Your Consent</strong>: For any other purpose you
                agree to.
              </li>
            </ul>
            <h3>Retention of Your Personal Data</h3>

            <p>
              We retain your Personal Data only as long as necessary for the
              purposes outlined in this Privacy Policy. Usage Data is typically
              retained for shorter periods unless needed for security or
              compliance.
            </p>
            <h3>Transfer of Your Personal Data</h3>

            <p>
              Your information may be transferred to computers located outside
              your jurisdiction. By providing your information, you consent to
              this transfer. We ensure your data is treated securely and in line
              with this Privacy Policy.
            </p>
            <h3>Delete Your Personal Data</h3>

            <p>
              You have the right to delete or request assistance in deleting
              your Personal Data. You can manage your information via your
              Account settings or by contacting us.
            </p>
            <h3>Disclosure of Your Personal Data</h3>

            <p>
              We may disclose your Personal Data in good faith under the
              following circumstances:
            </p>
            <ul>
              <li>
                <strong>Legal Obligations</strong>: If required by law or in
                response to valid requests by public authorities.
              </li>
              <li>
                <strong>Protecting the Rights of the Company</strong>: To
                protect and defend our rights and property.
              </li>
              <li>
                <strong>Preventing Potential Wrongdoing</strong>: To prevent or
                investigate possible wrongdoing in connection with the Service.
              </li>
              <li>
                <strong>Ensuring the Safety of Users</strong>: To protect the
                personal safety of users of the Service or the public.
              </li>
            </ul>
            <h3>Security of Your Personal Data</h3>

            <p>
              We value your Personal Data's security but cannot guarantee
              absolute security. We utilize commercially acceptable measures to
              protect your information, including encryption, access controls,
              and secure servers.
            </p>
            <h3>Children's Privacy</h3>

            <p>
              Our Service is not intended for anyone under 13. We do not
              knowingly collect Personal Data from minors. If you are a parent
              or guardian and believe we have collected information from your
              child, please contact us immediately.
            </p>
            <h3>Links to Other Websites</h3>

            <p>
              Our Service may contain links to third-party websites. We are not
              responsible for their content or privacy practices. We encourage
              you to review their privacy policies.
            </p>
            <h3>Changes to this Privacy Policy</h3>

            <p>
              We may update our Privacy Policy periodically. We will notify you
              of changes by posting the new policy on this page, along with the
              "Last updated" date.
            </p>
            <h2>Detailed Disclosures</h2>
            <h3>Types of Personal and Sensitive Data Collected</h3>

            <p>We may collect personal data, including but not limited to:</p>
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
            </ul>
            <h3>Third-Party Access</h3>

            <p>
              We may share your data with third parties as described in this
              Privacy Policy, including analytics and advertising partners. We
              ensure that these third parties handle your data responsibly and
              in compliance with applicable regulations.
            </p>
            <h3>Security Measures</h3>

            <p>
              We implement security measures to protect your data, including
              encryption, access controls, and secure servers.
            </p>
            <h3>
              We implement security measures to protect your data, including
              encryption, access controls, and secure servers.
            </h3>
            <p>
              We retain your Personal Data only for as long as necessary to
              fulfill the purposes outlined in this policy. You can request
              deletion of your Personal Data at any time, and we will comply in
              accordance with applicable laws.
            </p>
            <h3>User Rights</h3>

            <p>Users have the right to:</p>
            <ul>
              <li>
                You can access your personal data via your account settings or
                by contacting us.
              </li>
              <li>
                You can request corrections through your account settings or by
                contacting us directly.
              </li>
              <li>
                To request deletion, please contact us at the provided
                email:&nbsp;
                <a href="mailto:contact@workwavehub.com">
                  contact@workwavehub.com
                </a>
              </li>
            </ul>
            <h3>Compliance with Google Play Policies</h3>

            <p>
              This Privacy Policy is designed to comply with Google Play
              Developer Program Policies. We are committed to protecting your
              privacy and ensuring that our practices align with industry
              standards. Specifically, we have implemented the following
              measures:
            </p>
            <ul>
              <li>
                <strong>Link on Store Listing</strong>: This privacy policy is
                prominently linked on our Google Play store listing to provide
                easy access for users.
              </li>
              <li>
                <strong>Reference in App</strong>: Within the app, we provide a
                clear reference to this privacy policy, ensuring users can
                easily find and understand how their data is managed.
              </li>
              <li>
                <strong>Clear Labeling</strong>: This document is clearly
                labeled as the "Privacy Policy" to avoid any confusion regarding
                its purpose and content.
              </li>
              <li>
                <strong>Contact Point</strong>: We have provided a dedicated
                contact point above for user inquiries about privacy, allowing
                users to reach out with any questions or concerns regarding
                their personal data.
              </li>
              <li>
                <strong>Accessibility</strong>: This privacy policy is hosted on
                an active, public URL that is non-geofenced and fully accessible
                in standard web browsers, ensuring users can easily read and
                understand our privacy practices.
              </li>
              <li>
                <strong>Data Handling Transparency</strong>: We are transparent
                about how we collect, use, and share your personal data, as
                outlined in this policy. Users are encouraged to review this
                document to understand their rights and our responsibilities
                concerning their information.
              </li>
            </ul>
            <h3>Payment Information and Data Storage</h3>

            <p>
              When you make a payment through our platform, we collect and store
              certain payment-related information to facilitate your
              transactions. We do not directly store payment methods such as
              credit card numbers, but we may retain other data related to
              payment transactions, as described below:
            </p>
            <ul>
              <li>
                <strong>Transaction ID</strong>: For third-party payment
                processors (e.g., Stripe, PayPal, CoinPayment), we store the
                Transaction ID for reference and tracking purposes.
              </li>
              <li>
                <strong>USDT Crypto Address</strong>: If you use cryptocurrency
                for payments, we store the USDT crypto address associated with
                your account to complete the transaction.
              </li>
            </ul>
            <p>
              We only retain this data as necessary for fulfilling legal and
              regulatory obligations, dispute resolution, and transactional
              record-keeping. Your payment information is processed securely by
              third-party payment processors, and we ensure that our partners
              comply with relevant privacy and security standards.
            </p>
            <p>&nbsp;</p>
          </Box>
        ) : (
          <Box
            className="terms-content"
            style={{
              backgroundColor: "#fff",
              borderRadius: "20px",
            }}
          >
            <p>
              این خط&zwnj; مشی رازداری روش&zwnj;های ما را در مورد جمع&zwnj;آوری،
              استفاده و افشای اطلاعات شما در هنگام استفاده از خدمات ما ("سرویس")
              تشریح می&zwnj;کند.
            </p>
            <h3>مرجع نهاد</h3>
            <p>
              نهاد مسئول داده های شخصی شما WorkWaveHub است که به عنوان "ما"
              شناخته می شود.
            </p>
            <h3>برچسب گذاری خط مشی</h3>
            <p>
              این سند به عنوان "سیاست حفظ حریم خصوصی" برچسب گذاری شده است و
              اقدامات ما را در مورد مدیریت داده های شخصی نشان می دهد.
            </p>
            <h3>اطلاعات تماس</h3>
            <p>
              برای هر گونه سوال در مورد این سیاست حفظ حریم خصوصی یا اگر مایلید
              از حقوق خود در مورد داده های شخصی خود استفاده کنید، می توانید با
              ما تماس بگیرید:
            </p>
            <ul>
              <li>
                <strong>ایمیل</strong>&nbsp;:&nbsp;
                <a href="mailto:contact@workwavehub.com">
                  contact@workwavehub.com
                </a>
              </li>
              <li>
                <strong>وب سایت</strong>&nbsp;:&nbsp;
                <a href="https://workwavehub.com/contact">
                  http://workwavehub.com/contact
                </a>
              </li>
            </ul>
            <h3>قابلیت دسترسی</h3>
            <p>
              سیاست حفظ حریم خصوصی در یک مرورگر وب استاندارد قابل دسترسی است و
              نیازی به پلاگین یا نرم افزار اضافی ندارد.
            </p>
            <h2>تفسیر و تعاریف</h2>
            <h3>تفسیر</h3>
            <p>کلمات با حروف بزرگ در اینجا معانی خاصی دارند.</p>
            <h3>تعاریف</h3>
            <p>برای این سیاست حفظ حریم خصوصی:</p>
            <ul>
              <li>
                <strong>حساب</strong>&nbsp;: یک حساب منحصر به فرد ایجاد شده برای
                شما برای دسترسی به خدمات ما.
              </li>
              <li>
                <strong>وابسته</strong>: نهادی که کنترل، کنترل یا تحت کنترل
                مشترک با یک طرف است.
              </li>
              <li>
                <strong>برنامه</strong>: به WorkWaveHub، نرم افزار ارائه شده
                توسط ما اشاره دارد.
              </li>
              <li>
                <strong>شرکت</strong>: WorkWaveHub که به عنوان &laquo;ما&raquo;
                شناخته می&zwnj;شود.
              </li>
              <li>
                <strong>کوکی ها</strong>: فایل های کوچکی که در دستگاه شما قرار
                داده شده و حاوی جزئیات تاریخچه مرور شما هستند.
              </li>
              <li>
                <strong>کشور</strong>: اشاره به ایران است.
              </li>
              <li>
                <strong>دستگاه</strong>: هر دستگاهی که بتواند به سرویس دسترسی
                داشته باشد، مانند رایانه یا دستگاه تلفن همراه.
              </li>
              <li>
                <strong>داده های شخصی</strong>: اطلاعاتی که به یک فرد شناسایی
                شده یا قابل شناسایی مربوط می شود.
              </li>
              <li>
                <strong>خدمات</strong>: برنامه یا وب سایت یا هر دو.
              </li>
              <li>
                <strong>ارائه دهنده خدمات</strong>&nbsp;: هر نهادی که داده ها را
                از طرف ما پردازش می کند.
              </li>
              <li>
                <strong>خدمات رسانه های اجتماعی شخص ثالث</strong>:
                وب&zwnj;سایت&zwnj;ها یا شبکه&zwnj;های اجتماعی که به شما این
                امکان را می&zwnj;دهند که وارد شوید یا یک حساب کاربری برای سرویس
                ایجاد کنید.
              </li>
              <li>
                <strong>داده های استفاده</strong>: داده هایی که به طور خودکار
                جمع آوری می شوند و توسط سرویس یا زیرساخت آن تولید می شوند.
              </li>
              <li>
                <strong>وب سایت</strong>&nbsp;: WorkWaveHub، قابل دسترسی
                در&nbsp;
                <a href="https://workwavehub.com/">
                  https://www.workwavehub.com/
                </a>
                .
              </li>
              <li>
                <strong>شما</strong>&nbsp;: شخص حقیقی یا حقوقی که به سرویس
                دسترسی یا استفاده می کند.
              </li>
            </ul>
            <h2>جمع آوری و استفاده از داده های شخصی شما</h2>
            <h3>انواع داده های جمع آوری شده</h3>
            <p>
              در حین استفاده از خدمات ما، ممکن است از شما بخواهیم اطلاعات
              شناسایی شخصی خاصی را در اختیار ما قرار دهید که می تواند برای تماس
              یا شناسایی شما استفاده شود.
            </p>
            <ul>
              <li>آدرس ایمیل</li>
              <li>نام و نام خانوادگی</li>
              <li>شماره تلفن</li>
            </ul>
            <h3>استفاده از داده های شخصی شما</h3>
            <p>
              ما ممکن است از داده های شخصی شما برای اهداف مختلف استفاده کنیم، از
              جمله:
            </p>
            <ul>
              <li>ارائه و نگهداری خدمات ما</li>
              <li>مدیریت حساب شما</li>
              <li>تماس با شما برای به روز رسانی و اطلاعات تبلیغاتی.</li>
              <li>تجزیه و تحلیل داده ها برای بهبود خدمات ما.</li>
            </ul>
            <h3>به اشتراک گذاری داده های شخصی شما</h3>
            <p>
              ما ممکن است اطلاعات شخصی شما را در شرایط زیر به اشتراک بگذاریم:
            </p>
            <ul>
              <li>
                <strong>با ارائه دهندگان خدمات</strong>: برای نظارت و تجزیه و
                تحلیل استفاده.
              </li>
              <li>
                <strong>برای نقل و انتقالات تجاری</strong>: در طول مذاکرات ادغام
                یا تملک.
              </li>
              <li>
                <strong>با وابسته ها</strong>: با شرکت مادر یا شرکت های تابعه
                ما.
              </li>
              <li>
                <strong>با شرکای تجاری</strong>: برای ارائه محصولات یا خدمات
                خاص.
              </li>
              <li>
                <strong>با رضایت شما</strong>: برای هر هدف دیگری که با آن موافقت
                می کنید.
              </li>
            </ul>
            <h3>حفظ اطلاعات شخصی شما</h3>
            <p>
              ما داده های شخصی شما را فقط تا زمانی که برای اهداف ذکر شده در این
              سیاست حفظ حریم خصوصی لازم باشد حفظ می کنیم.
            </p>
            <h3>انتقال داده های شخصی شما</h3>
            <p>
              اطلاعات شما ممکن است به رایانه هایی که خارج از حوزه قضایی شما قرار
              دارند منتقل شود.
            </p>
            <h3>اطلاعات شخصی خود را حذف کنید</h3>
            <p>شما حق دارید اطلاعات شخصی خود را حذف یا درخواست کمک کنید.</p>
            <h3>افشای اطلاعات شخصی شما</h3>
            <p>
              ممکن است تحت شرایط زیر اطلاعات شخصی شما را با حسن نیت افشا کنیم:
            </p>
            <ul>
              <li>
                <strong>تعهدات قانونی</strong>: در صورت لزوم توسط قانون یا در
                پاسخ به درخواست های معتبر مقامات دولتی.
              </li>
              <li>
                <strong>حفظ حقوق شرکت</strong>: برای حفظ و دفاع از حقوق و اموال
                خود.
              </li>
              <li>
                <strong>جلوگیری از اشتباهات احتمالی</strong>: برای جلوگیری یا
                بررسی تخلفات احتمالی در ارتباط با سرویس.
              </li>
              <li>
                <strong>اطمینان از ایمنی کاربران</strong>: برای حفظ امنیت شخصی
                کاربران سرویس یا عموم مردم.
              </li>
            </ul>
            <h3>امنیت داده های شخصی شما</h3>
            <p>
              ما برای امنیت داده های شخصی شما ارزش قائل هستیم اما نمی توانیم
              امنیت مطلق را تضمین کنیم.
            </p>
            <h3>حریم خصوصی کودکان</h3>
            <p>
              خدمات ما برای افراد زیر 13 سال در نظر گرفته نشده است. ما آگاهانه
              داده های شخصی افراد زیر سن قانونی را جمع آوری نمی کنیم.
            </p>
            <h3>پیوند به وب سایت های دیگر</h3>
            <p>
              خدمات ما ممکن است حاوی پیوندهایی به وب سایت های شخص ثالث باشد.
            </p>
            <h3>تغییرات در این سیاست حفظ حریم خصوصی</h3>
            <p>
              ممکن است سیاست حفظ حریم خصوصی خود را به صورت دوره ای به روز کنیم.
            </p>
            <h2>افشای تفصیلی</h2>
            <h3>انواع داده های شخصی و حساس جمع آوری شده</h3>
            <p>ما ممکن است داده های شخصی را جمع آوری کنیم، از جمله:</p>
            <ul>
              <li>آدرس ایمیل</li>
              <li>نام و نام خانوادگی</li>
              <li>شماره تلفن</li>
            </ul>
            <h3>دسترسی شخص ثالث</h3>
            <p>
              ما ممکن است داده های شما را با اشخاص ثالث همانطور که در این خط مشی
              رازداری توضیح داده شده است، از جمله شرکای تجزیه و تحلیل و تبلیغات،
              به اشتراک بگذاریم.
            </p>
            <h3>اقدامات امنیتی</h3>
            <p>
              ما اقدامات امنیتی را برای محافظت از داده های شما اجرا می کنیم، از
              جمله رمزگذاری، کنترل های دسترسی و سرورهای ایمن.
            </p>
            <h3>
              ما اقدامات امنیتی را برای محافظت از داده های شما اجرا می کنیم، از
              جمله رمزگذاری، کنترل های دسترسی و سرورهای ایمن
            </h3>
            <p>
              ما داده های شخصی شما را فقط تا زمانی که برای تحقق اهداف ذکر شده در
              این خط مشی ضروری است حفظ می کنیم.
            </p>
            <h3>حقوق کاربر</h3>
            <p>کاربران حق دارند:</p>
            <ul>
              <li>
                شما می توانید از طریق تنظیمات حساب کاربری خود یا با تماس با ما
                به اطلاعات شخصی خود دسترسی داشته باشید.
              </li>
              <li>
                می توانید از طریق تنظیمات حساب خود یا با تماس مستقیم با ما
                درخواست اصلاح کنید.
              </li>
              <li>
                برای درخواست حذف، لطفاً با ایمیل ارائه شده با ما تماس
                بگیرید:&nbsp;
                <a href="mailto:contact@workwavehub.com">
                  contact@workwavehub.com
                </a>
              </li>
            </ul>
            <h3>مطابقت با خط&zwnj;مشی&zwnj;های</h3>

            <p>
              این خط&zwnj;مشی رازداری برای مطابقت با خط&zwnj;مشی&zwnj;های برنامه
              برنامه&zwnj;نویس Google Play طراحی شده است.
            </p>
            <ul>
              <li>
                <strong>پیوند در فهرست فروشگاه</strong>: این خط مشی رازداری به
                طور برجسته در فهرست فروشگاه Google Play ما پیوند داده شده است تا
                دسترسی آسان را برای کاربران فراهم کند.
              </li>
              <li>
                <strong>مرجع در برنامه</strong>: در داخل برنامه، ما یک مرجع واضح
                به این خط مشی حفظ حریم خصوصی ارائه می دهیم و اطمینان می دهیم که
                کاربران به راحتی می توانند نحوه مدیریت داده های خود را بیابند و
                درک کنند.
              </li>
              <li>
                <strong>پاک کردن برچسب زدن</strong>: این سند به وضوح به عنوان
                "سیاست حفظ حریم خصوصی" برچسب گذاری شده است تا از هرگونه سردرگمی
                در مورد هدف و محتوای آن جلوگیری شود.
              </li>
              <li>
                <strong>نقطه تماس</strong>: ما یک نقطه تماس اختصاصی در بالا برای
                سؤالات کاربر در مورد حریم خصوصی ارائه کرده&zwnj;ایم که به
                کاربران امکان می&zwnj;دهد با هرگونه سؤال یا نگرانی در مورد
                داده&zwnj;های شخصی خود ارتباط برقرار کنند.
              </li>
              <li>
                <strong>قابلیت دسترسی</strong>: این خط&zwnj;مشی رازداری بر روی
                یک URL فعال و عمومی میزبانی می&zwnj;شود که دارای محفظه جغرافیایی
                نیست و به طور کامل در مرورگرهای وب استاندارد قابل دسترسی است،
                این اطمینان را می&zwnj;دهد که کاربران به راحتی می&zwnj;توانند
                شیوه&zwnj;های حریم خصوصی ما را بخوانند و درک کنند.
              </li>
              <li>
                <strong>شفافیت مدیریت داده ها</strong>: ما در مورد نحوه جمع
                آوری، استفاده و اشتراک گذاری داده های شخصی شما، همانطور که در
                این خط مشی مشخص شده است، شفاف هستیم.
              </li>
            </ul>
            <h3>اطلاعات پرداخت و ذخیره داده ها</h3>
            <p>
              هنگامی که از طریق پلتفرم ما پرداختی انجام می دهید، ما اطلاعات
              مربوط به پرداخت را جمع آوری و ذخیره می کنیم تا تراکنش های شما را
              تسهیل کنیم.
            </p>
            <ul>
              <li>
                <strong>شناسه تراکنش</strong>: برای پردازشگرهای پرداخت شخص ثالث
                (به عنوان مثال، Stripe، PayPal، CoinPayment)، شناسه تراکنش را
                برای اهداف مرجع و ردیابی ذخیره می کنیم.
              </li>
              <li>
                <strong>آدرس رمزنگاری</strong>
                <strong> USDT</strong>: اگر از ارز دیجیتال برای پرداخت استفاده
                می کنید، ما آدرس رمزارز USDT مرتبط با حساب شما را برای تکمیل
                تراکنش ذخیره می کنیم.
              </li>
            </ul>
            <p>
              ما این داده ها را فقط در صورت لزوم برای انجام تعهدات قانونی و
              نظارتی، حل اختلاف و نگهداری سوابق تراکنش حفظ می کنیم.
              <br /> اطلاعات پرداخت شما به&zwnj;صورت امن توسط پردازشگران پرداخت
              شخص ثالث پردازش می&zwnj;شود و ما اطمینان حاصل می&zwnj;کنیم که
              شرکای ما از استانداردهای مربوط به حریم خصوصی و امنیت پیروی کنند.
            </p>
          </Box>
        )}
      </Container>
    </section>
  );
};

export default PrivacyPolicy;
