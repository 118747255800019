import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  Modal,
  Button,
  FormLabel,
  InputBase,
  InputAdornment,
  Stack,
} from "@mui/material";
import deleteVec from "../../../Assets/Images/deleteVector.png";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import editICO from "../../../Assets/Images/EditIcon.png";
import deleteICO from "../../../Assets/Images/DeleteICon.png";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import { getStatusChip } from "./utils"; // Ensure this path is correct
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import { deleteAPI, getAPI } from "../../../Services/Api";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../Auth/Firebase/firebase";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import eye from "../../../Assets/Images/Eye.png";

const UserManagementTable = ({ roles, onEditClick, isWWH }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  // Modal state
  const [open, setOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [status, setStatus] = useState(""); // Default to 'active'
  const [role, setRole] = useState(""); // Default to 'active'
  const [showPagination, setShowPagination] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [timeOutId, setTimeOutId] = useState(false);

  const { t } = useTranslation();

  const statusOptions = [
    { value: "Activated", label: t("Activate") },
    { value: "Suspended", label: t("Suspend") },
    { value: "Inactive", label: t("Inactive") },
  ];

  const filter = async () => {
    setShowPagination(false);
    await fetchUser();
    setShowPagination(true);
  };

  const fetchUser = async (
    ev = {},
    pageNumber = 1,
    st = status,
    rl = role,
    search = searchText
  ) => {
    LoaderShow();
    await fetchSearchedUser(ev, pageNumber, st, rl);
    LoaderHide();
  };

  const fetchSearchedUser = async (
    ev = {},
    pageNumber = 1,
    st = status,
    rl = role,
    search = searchText
  ) => {
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        let response;
        if (isWWH) {
          response = await getAPI(
            `user/rest-list?page=${pageNumber}&roleId=${rl}&status=${st}&search=${search}`,
            token
          );
        } else {
          response = await getAPI(
            `user/list-employee-employer?page=${pageNumber}&roleId=${rl}&status=${st}&search=${search}`,
            token
          );
        }
        if (response.statusCode == 200) {
          setTotalPages(response?.data?.totalPages || 1);
          setUsers(response?.data?.users || []);
        }
      } catch (err) {
        console.log(t(err.message));
        toast.error(t(err.message));
      }
    }
    setFilterModalOpen(false);
  };

  const handleSearchChange = (event) => {
    if (timeOutId) clearTimeout(timeOutId);
    setTimeOutId(
      setTimeout(async () => {
        setShowPagination(false);
        setSearchText(event.target.value);
        await fetchSearchedUser({}, 1, status, role, event.target.value);
        setShowPagination(true);
      }, 500)
    );
  };

  useEffect(() => {
    setOpen(false);
    setFilterModalOpen(false);
    setSearchText("");
    setStatus("");
    setRole("");
    setShowPagination(false);
    fetchUser({}, "", "", "", "");
    setShowPagination(true);
  }, [isWWH]);

  const handleOpen = (user) => {
    setUserToDelete(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUserToDelete(null);
  };

  const clearFilter = async () => {
    if (status || role) {
      setStatus("");
      setRole("");
      setShowPagination(false);
      await fetchUser({}, 1, "", "");
      setShowPagination(true);
    }
  };

  const handleConfirmDelete = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const data = {
        number: userToDelete?.phoneNumber,
      };
      if (token && data.number) {
        const response = await deleteAPI("user/delete-user", data, token);
        if (response.statusCode == 200) {
          if (userToDelete?.firebaseUID) {
            await deleteDoc(doc(db, "users", userToDelete.firebaseUID));
            const roomRef = collection(db, "rooms");
            const roomQuery = query(
              roomRef,
              where("userIds", "array-contains", userToDelete.firebaseUID)
            );
            const rooms = await getDocs(roomQuery);
            rooms.forEach(async (documentSnapshot) => {
              await deleteDoc(doc(db, "rooms", documentSnapshot.id));
            });
            const supportRef = collection(db, "supportChat");
            const supportQuery = query(
              supportRef,
              where("userId", "==", userToDelete.firebaseUID)
            );
            const supports = await getDocs(supportQuery);
            supports.forEach(async (documentSnapshot) => {
              await deleteDoc(doc(db, "supportChat", documentSnapshot.id));
            });
          }
          setUsers((users) =>
            users.filter((ele) => ele?._id != userToDelete?._id)
          );
          toast.success(t("toast32"));
          handleClose();
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 3,
          justifyContent: "end",
        }}
      >
        <InputBase
          placeholder={t("SearchText")}
          onChange={handleSearchChange}
          sx={{
            maxWidth: "400px",
            ml: 1,
            flex: 1,
            backgroundColor: "#fff",
            color: "#000",
            borderRadius: "24px",
            py: "8px",
            px: "20px",
            justifyContent: "flex-end",
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#000" }} />
            </InputAdornment>
          }
        />
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <Table
          aria-label="user management table"
          sx={{ borderCollapse: "separate", borderSpacing: "0" }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  padding: "24px 24px",
                  fontFamily: "inherit",
                  fontWeight: "bold",
                  fontSize: "16px",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                {t("listOfUSer")}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "24px 24px",
                  fontFamily: "inherit",
                  fontWeight: "bold",
                  fontSize: "16px",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                {t("role")}
                <IconButton
                  onClick={(ev) => setFilterModalOpen(true)}
                  size="small"
                  sx={{ ml: 1 }}
                >
                  <FilterAltOutlinedIcon sx={{ color: "#0B3013" }} />
                </IconButton>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  padding: "24px 24px",
                  fontFamily: "inherit",
                  fontWeight: "bold",
                  fontSize: "16px",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                {t("Status")}
                <IconButton
                  onClick={(ev) => setFilterModalOpen(true)}
                  size="small"
                  sx={{ ml: 1 }}
                >
                  <FilterAltOutlinedIcon sx={{ color: "#0B3013" }} />
                </IconButton>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  padding: "24px 24px",
                  fontFamily: "inherit",
                  fontWeight: "bold",
                  fontSize: "16px",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                {t("Action")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={user._id}>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    borderBottom:
                      index === users.length - 1 ? "0" : "1px solid #e0e0e0",
                  }}
                >
                  {user.firstName || ""} {user.lastName || ""}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    maxWidth: "200px",
                    borderBottom:
                      index === users.length - 1 ? "0" : "1px solid #e0e0e0",
                  }}
                >
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {user?.role?.length > 0
                      ? user?.role?.map(
                          (ele) =>
                            ele?.name && (
                              <Chip
                                label={ele?.name}
                                sx={{
                                  cursor: "default",
                                  textTransform: "capitalize",
                                  py: 1.1,
                                  px: 1,
                                  backgroundColor: "#2ab95f",
                                  color: "#fff",
                                  borderRadius: "30px",
                                  fontSize: "14px",
                                }}
                              />
                            )
                        )
                      : "-"}
                  </Stack>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    borderBottom:
                      index === users.length - 1 ? "0" : "1px solid #e0e0e0",
                  }}
                >
                  {getStatusChip(user.status, t)}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    borderBottom:
                      index === users.length - 1 ? "0" : "1px solid #e0e0e0",
                  }}
                >
                  <IconButton
                    sx={{
                      backgroundColor: "#f7fdf9",
                      borderRadius: "4px",
                      p: 0.5,
                    }}
                    color="primary"
                    aria-label="edit"
                    onClick={() => onEditClick(user)}
                  >
                    <img src={editICO} alt="Edit" />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "#f7fdf9",
                      borderRadius: "4px",
                      mx: 1,
                      p: 0.5,
                    }}
                    aria-label="delete"
                    onClick={() => handleOpen(user)}
                  >
                    <img src={deleteICO} alt="Delete" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {users.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}>
            {showPagination && (
              <CustomPagination count={totalPages} onChange={fetchUser} />
            )}
          </Box>
        )}
        {users.length <= 0 && <NodataFound />}

        <Modal
          open={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          aria-labelledby="logout-modal"
          aria-describedby="logout-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 510 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3,
              borderRadius: 4,
            }}
          >
            <Typography variant="h5" sx={{ mb: 1, fontWeight: 500 }}>
              {t("Filter")}
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                top: 14,
                right: 10,
              }}
              onClick={() => setFilterModalOpen(false)}
            >
              <img src={close} alt="Close" />
            </IconButton>
            <Box sx={{ borderTop: 1, borderColor: "#dededf" }}>
              <FormLabel
                sx={{
                  display: "block",
                  textAlign: "start",
                  py: 1.4,
                  fontSize: 14,
                }}
              >
                {t("role")}
              </FormLabel>
              <Dropdown
                value={role}
                onChange={(ev) => setRole(ev.target.value)}
                options={roles}
                label="Role"
              />
              <FormLabel
                sx={{
                  display: "block",
                  textAlign: "start",
                  py: 1.4,
                  fontSize: 14,
                }}
              >
                {t("Status")}
              </FormLabel>
              <Dropdown
                value={status}
                onChange={(ev) => setStatus(ev.target.value)}
                options={statusOptions}
                label="Status"
              />
            </Box>
            <Box sx={{ my: 3, display: "flex" }}>
              <Button
                onClick={clearFilter}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  textTransform: "capitalize",
                  bgcolor: "#0a3114",
                  fontSize: 16,
                  color: "#fff",
                  borderRadius: 10,
                  px: 5,
                  py: 1,
                  "&:hover": {
                    bgcolor: "#0a3114",
                  },
                }}
                endIcon={<ArrowOutwardIcon color="#fff" />}
              >
                {t("clear")}
              </Button>{" "}
              <Button
                onClick={filter}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  textTransform: "capitalize",
                  bgcolor: "#0a3114",
                  fontSize: 16,
                  color: "#fff",
                  borderRadius: 10,
                  px: 5,
                  py: 1,
                  "&:hover": {
                    bgcolor: "#0a3114",
                  },
                }}
                endIcon={<ArrowOutwardIcon color="#fff" />}
              >
                {t("Filter")}
              </Button>{" "}
            </Box>
          </Box>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 4,
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
            >
              <img src={close} alt="Close" />
            </IconButton>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: "center",
                  py: 1,
                }}
              >
                <img
                  className="deleteVec"
                  src={deleteVec}
                  alt="Delete Vector"
                />
              </Box>
              <Typography
                id="logout-modal-description"
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: 32,
                  textAlign: "center",
                  py: 2,
                  pb: 1,
                }}
                gutterBottom
              >
                {t("areYouSure")}
              </Typography>
              <Typography
                id="logout-modal-description"
                variant="body1"
                sx={{ fontSize: 18, textAlign: "center", display: "block" }}
                gutterBottom
              >
                {t("actionunDone")}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 4,
                  py: 1,
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 6,
                  py: 1,
                }}
                variant="contained"
                onClick={handleConfirmDelete}
              >
                {t("Yes")}
              </Button>
            </Box>
          </Box>
        </Modal>
      </TableContainer>
    </>
  );
};

export default UserManagementTable;
