import * as React from "react";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import fb from "../../Assets/Images/facebook-01.png";
import twitter from "../../Assets/Images/twitter.png";
// import yt from "../../Assets/Images/youtube.png";
import insta from "../../Assets/Images/instagram.svg";
import twitch from "../../Assets/Images/twitch.png";
import play from "../../Assets/Images/GooglePlay.png";
import App from "../../Assets/Images/Appstore.png";
import { useTranslation } from "react-i18next";
import "../../Localization/i18n";
import "./Footer.css";
import { Link } from "react-router-dom";
import { getAPI } from "../../Services/Api";
import Button from "@mui/material/Button";

export default function Footer() {
  const [projectData, setProjectData] = React.useState([]);

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    getProjectData();
  }, []);

  const getProjectData = async () => {
    try {
      const response = await getAPI(`project/dropdown`);
      if (response.statusCode === 200) {
        setProjectData(response?.data);
      }
    } catch (error) {
      console.log("error===", error);
    }
  };

  return (
    <Box className="" color="black">
      <div className="footer" id="footerBG">
        <Container
          maxWidth="xl"
          sx={{ px: { md: 8, xl: 4, xxl: 4 }, width: "100vw" }}
        >
          <Box className="" sx={{ py: 1 }}>
            <Box
              className="headSection"
              sx={{ borderBottom: 1, py: 3, borderColor: "#fff", mr: "35px" }}
            >
              <div className="">
                {/* <Link to="/terms-and-conditions" className="Lnk">
                  <Typography
                    className="plZero"
                    variant="p"
                    sx={{
                      color: "#fff",
                      px: 2,
                      paddingLeft: 0,
                      textDecoration: "none",
                    }}
                  >
                    {t("Terms")}
                  </Typography>
                </Link>
                <Link to="/privacypolicy" className="Lnk">
                  <Typography
                    className="plZero"
                    variant="p"
                    sx={{ color: "#fff", px: 2 }}
                  >
                    {t("Privacy")}
                  </Typography>
                </Link>
                <Typography
                  className="plZero"
                  variant="p"
                  sx={{ color: "#fff", px: 2 }}
                >
                  {t("Site")}{" "}
                </Typography> */}
              </div>
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                className="catBtnTop followSection"
              >
                <Typography
                  variant="p"
                  sx={{ color: "#fff", px: 2, paddingLeft: 0 }}
                >
                  {t("Followus")}
                </Typography>
                <a href="https://www.facebook.com/people/Work-Wave-Hub/61559906970775/">
                  <img className="socialIco" src={fb} alt="fb" />
                </a>
                {/* <a href="https://twitter.com/workwavehub">
                  <img className="socialIco" src={twitter} alt="twitter" />
                </a> */}
                <a href="https://www.instagram.com/workwavehub2024">
                  <img
                    style={{ opacity: 0.76 }}
                    className="socialIco"
                    height={"24px"}
                    width={"24px"}
                    src={insta}
                    alt="insta"
                  />
                </a>
                {/* <img className="socialIco" src={yt} alt="fb" /> */}
                <a href="https://t.me/workwavehub">
                  <svg
                    style={{
                      opacity: 0.76,
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#FFFFFF"
                    viewBox="0 0 64 64"
                    height={"32px"}
                    width={"32px"}
                  >
                    <path d="M 32 10 C 19.85 10 10 19.85 10 32 C 10 44.15 19.85 54 32 54 C 44.15 54 54 44.15 54 32 C 54 19.85 44.15 10 32 10 z M 32 14 C 41.941 14 50 22.059 50 32 C 50 41.941 41.941 50 32 50 C 22.059 50 14 41.941 14 32 C 14 22.059 22.059 14 32 14 z M 41.041016 23.337891 C 40.533078 23.279297 39.894891 23.418531 39.181641 23.675781 C 37.878641 24.145781 21.223719 31.217953 20.261719 31.626953 C 19.350719 32.014953 18.487328 32.437828 18.486328 33.048828 C 18.486328 33.478828 18.741312 33.721656 19.445312 33.972656 C 20.177313 34.234656 22.023281 34.79275 23.113281 35.09375 C 24.163281 35.38275 25.357344 35.130844 26.027344 34.714844 C 26.736344 34.273844 34.928625 28.7925 35.515625 28.3125 C 36.102625 27.8325 36.571797 28.448688 36.091797 28.929688 C 35.611797 29.410688 29.988094 34.865094 29.246094 35.621094 C 28.346094 36.539094 28.985844 37.490094 29.589844 37.871094 C 30.278844 38.306094 35.239328 41.632016 35.986328 42.166016 C 36.733328 42.700016 37.489594 42.941406 38.183594 42.941406 C 38.877594 42.941406 39.242891 42.026797 39.587891 40.966797 C 39.992891 39.725797 41.890047 27.352062 42.123047 24.914062 C 42.194047 24.175062 41.960906 23.683844 41.503906 23.464844 C 41.365656 23.398594 41.210328 23.357422 41.041016 23.337891 z" />
                  </svg>
                </a>
              </Box>
            </Box>
          </Box>
          <Box sx={{ py: 4 }}>
            <div className="content">
              <div className="services">
                <h4 className="mainFooteHead">{t("AboutOne")}</h4>
                <Link to="/privacypolicy" className="Lnk">
                  <p>
                    <a className="textSubFoote" href="#/">
                      {t("Privacy")}
                    </a>
                  </p>
                </Link>
                <Link to="/terms-and-conditions" className="Lnk">
                  <p>
                    <a className="textSubFoote" href="#/">
                      {t("Terms")}
                    </a>
                  </p>
                </Link>
              </div>
              <div className="social-media">
                <h4 className="mainFooteHead">{t("CategoriesOne")}</h4>
                {projectData?.categories?.slice(0, 7)?.map((ele) => (
                  <p key={ele?._id}>
                    <a className="textSubFoote" href="#/">
                      {
                        ele?.[
                          i18n.language == "en"
                            ? "name"
                            : `name_${i18n.language}`
                        ]
                      }
                    </a>
                  </p>
                ))}
              </div>
              <div className="links">
                <h4 className="mainFooteHead">{t("Support")}</h4>
                <Link to="/help" className="Lnk">
                  <p>
                    <a className="textSubFoote">{t("HelpSupport")}</a>
                  </p>
                </Link>
                <Link to="/contact" className="Lnk">
                  <p>
                    <a className="textSubFoote" href="#/">
                      {t("Contact")}
                    </a>
                  </p>
                </Link>
              </div>
              <div className="details">
                {/* <h4 className="mainFooteHead">{t("Subscribe")}</h4>
                <div className="searchbox-wrap">
                  <input type="text" placeholder={t("Your")} />
                  <button>
                    <span>{t("Send")}</span>{" "}
                  </button>
                </div> */}
                <Box className="" sx={{ pb: 4 }}>
                  <h4 className="mainFooteHead">{t("Downloads")}</h4>
                  <Box
                    className=""
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.app.workwavehub"
                      target="_blank"
                    >
                      <img
                        src={play}
                        className="downloadImage"
                        width={200}
                        alt="play"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/workwavehub/id6657975299"
                      target="_blank"
                    >
                      <img
                        src={App}
                        className="downloadImage"
                        width={200}
                        alt="play"
                      />
                    </a>
                  </Box>

                  <h4 className="mainFooteHead" style={{ marginTop: "20px" }}>
                    {t("Downloads_v2")}
                  </h4>
                  <Box
                    className=""
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <a
                      href="/workwavehub-android.apk"
                      download
                      style={{
                        backgroundColor: "#fff",
                        color: "#000",
                        borderRadius: "50px",
                        padding: "10px 20px",
                        textTransform: "none",
                        textAlign: "Center",
                        fontWeight: "bold",
                        marginBottom: "20px",
                      }}
                    >
                      Android APK
                    </a>
                    <a
                      href="https://testflight.apple.com/join/1XMRDtuz"
                      target="_blank"
                      style={{
                        backgroundColor: "#fff",
                        color: "#000",
                        borderRadius: "50px",
                        padding: "10px 20px",
                        textTransform: "none",
                        textAlign: "Center",
                        fontWeight: "bold",
                      }}
                    >
                      IOS APK
                    </a>
                  </Box>
                </Box>
              </div>
            </div>
          </Box>
          <footer>
            <hr />
            <Typography sx={{ color: "#fff" }}>{t("AllRights")} </Typography>
          </footer>
        </Container>
      </div>
    </Box>
  );
}
